<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/tamogiefectivo/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">BEN GREEN VIET NAM COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li :class="{ 'active-link': (isActive('/') || isActive('/vn/')) }" class="Li1">
          <router-link to="/" class="nav-link1">
            <span class="routeText1">Về chúng tôi</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/vn/about' }" class="Li2">
          <router-link to="/vn/about" class="nav-link2">
            <span class="routeText2">Khoản vay của chúng tôi</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/vn/question' }" class="Li3">
          <router-link to="/vn/question" class="nav-link3">
            <span class="routeText3">Trung tâm trợ giúp</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/vn/protocol' }" class="Li4">
          <!-- <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Cam kết bảo mật</span>
          </router-link> -->
          <a href="https://rlvaw.rapidloanapp.com/help/privacy_policy" class="nav-link3" target="_blank">
            <span class="routeText4">Cam kết bảo mật</span>
          </a>
        </li>
      </ul>
    </div>
    <!-- <div style="height: 110px"></div> -->
    <div class="modBox" ref="mod">
      <a-modal v-model="open" :getContainer="() => $refs.mod" :closable="false" :footer="null" :mask="false"
        :maskClosable="false">
        <div class="clickBox1" @click="close()"></div>
        <div class="clickBox2" @click="gotoCountry('vn')"></div>
        <div class="clickBox3" @click="gotoCountry('th')"></div>
      </a-modal>
    </div>
    <!-- <div class="modBox" ref="mod">
      <a-modal v-model="open" :getContainer="() => $refs.mod" :closable="false" :footer="null" :mask="false"
        :maskClosable="false">
        <div class="mod-title">Vui lòng chọn ngôn ngữ của quốc gia bạn โปรดเลือกภาษาของประเทศของคุณ</div>
        <div class="mod-row">
          <img :src="vnIcon" alt="" class="countryIcon" />
          <div class="selectBox" @click="gotoCountry('vn')">Tiếng Việt</div>
        </div>
        <div class="mod-row">
          <img :src="thIcon" alt="" class="countryIcon" />
          <div class="selectBox" @click="gotoCountry('th')">แบบไทย</div>
        </div>
      </a-modal>
    </div> -->
  </nav>
</template>

<script>
// import vnIcon from "@/page/bengreenvietnamcompanylimited/vn/components/img/vnIcon.png";
// import thIcon from "@/page/bengreenvietnamcompanylimited/vn/components/img/thIcon.png";
// import modalBg from "@/page/bengreenvietnamcompanylimited/vn/components/img/modalBg.png";
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/jaidee/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
      // vnIcon: vnIcon,
      // thIcon: thIcon,
      open: false,
    };
  },
  mounted() {
    if (window.performance.navigation.type == 1) {
      // console.log("页面被刷新！")
    } else {
      // console.log("首次被加载！")
      if (['/', '/vn/'].includes(this.$route.path)) {
        this.open = true;
      }
    }
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    close() {
      this.open = false;
    },
    gotoCountry(country) {
      if (country === 'vn') {
        this.close();
      }
      if (country === 'th') {
        this.$router.push('/th/');
      }
    },

  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky;
  /* 将导航栏固定在页面上 */
  top: 0;
  /* 位于页面顶部 */
  z-index: 1000;
  /* 确保导航栏在其他元素上方 */
  height: 80px;
}

.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #FFFFFF;
}


.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
  margin-left: 50px;
}

.title {
  width: 460px;
  height: 24px;
  font-size: 16px;
  font-family: Roboto Flex-SemiBold;
  font-weight: 600;
  color: #000000;
  line-height: 23px;
  /* padding-left: 18px; */
  padding-left: 80px;
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: Roboto Flex-Medium;
  /* font-weight: 600; */
  color: #666666;
  line-height: 29px;
}

.routeText1 {
  width: 85px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText2 {
  width: 148px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText3 {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}

.routeText4 {
  width: 200px;
  padding-left: 16px;
  padding-right: 8px;
  text-align: center;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  height: 49px;
  font-weight: 500;
  line-height: 39px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  padding-top: 5px;
  padding-bottom: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 49px;
  /* border: 1px solid  red; */
  text-align: center;
}

.Li1 {
  width: 160px;
}

.Li2 {
  width: 240px;
}

.Li3 {
  width: 190px;
}

.Li4 {
  width: 180px;
}

.lastLi {
  width: 190px;
  margin-right: 28px;
}

/* .active-link {
  background-size: 100% 100%;
} */
.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4,
.active-link .routeText5 {
  font-size: 18px;
  font-family: Inter-Extra Bold;
  font-weight: 600;
  color: #354229;
}

.active-link .routeText1::after,
.active-link .routeText2::after,
.active-link .routeText3::after,
.active-link .routeText4::after,
.active-link .routeText5::after {
  content: "";
  display: block;
  /* 初始时下划线可见 */
  border-bottom: 4px solid #354229;
  border-radius: 20px;
  /* 调整下划线的长度 */
  width: 50%;
  transition: width 0s ease-in-out;
  /* 添加 transition 效果 */
  /* margin-top: 10px; */
}

.active-link .routeText1::after {
  width: 60%;
  margin-left: 36px;
}

.active-link .routeText2::after {
  width: 60%;
  margin-left: 50px;
}

.active-link .routeText3::after {
  margin-left: 30px;
  width: 70%;
}

.active-link .routeText4::after {
  width: 67%;
  margin-left: 35px;
}

.title {
  /* border: 1px solid red; */
  width: 500px;
  /* padding-left: 80px; */
}

.secondBox {
  padding: 0 50px 0 30px;
}

.modBox>>>.ant-modal-content {
  width: 500px;
  background-image: url("@/page/bengreenvietnamcompanylimited/vn/components/img/modalBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 310px;
  background-color: #f5fff6;
  margin-top: 70px;
}

/* .modBox>>>.ant-modal-body {
  background-color: #3b4453;
  width: 500px;
  height: 310px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-size: 20px;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.modBox>>>.ant-modal-body {
  /* background-image: url("@/page/bengreenvietnamcompanylimited/vn/components/img/modalBg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 310px; */
}

.countryIcon {
  width: 66px;
  height: 46px;
}

.mod-title {
  width: 400px;
  text-align: center;
  margin: 10px 0 26px;
}

.mod-row {
  display: flex;
  width: 420px;
  margin-bottom: 30px;
  align-items: center;
}

.selectBox {
  width: 230px;
  height: 54px;
  border: 1px solid #FFFFFF;
  text-align: center;
  line-height: 54px;
  border-radius: 3px;
  margin-left: 20px;
}

.modBox {
  position: relative;
}

.clickBox1 {
  position: absolute;
  background: transparent;
  width: 42px;
  height: 43px;
  top: 0%;
  right: 0%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox2 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 43px;
  top: 51%;
  left: 42%;
  cursor: pointer;
  /* border: 1px solid red; */
}

.clickBox3 {
  position: absolute;
  background: transparent;
  width: 140px;
  height: 43px;
  top: 73%;
  left: 42%;
  cursor: pointer;
  /* border: 1px solid red; */
}
</style>