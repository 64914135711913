<template>
  <div class="textBox">{{ vnText }}{{ thText }}</div>

  <!-- <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <img :src="importedImg3" alt="" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <img :src="importedImg4" alt="" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <img :src="importedImg5" alt="" class="imgItem5" />
      </div>
    </div>
  </div> -->
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_1.png";
import importedImg2 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_2.png";
import importedImg3 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_3.png";
import importedImg4 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_4.png";
import importedImg5 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_5.png";
// import importedImg6 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_6.png";
// import importedImg7 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_7.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      altText: "图片",
      vnText: `Chính sách quyền riêng tư này mô tả các chính sách và thủ tục của chúng tôi về việc thu thập, sử dụng và tiết lộ thông tin của bạn khi bạn sử dụng Dịch vụ, đồng thời cho bạn biết về quyền riêng tư của bạn cũng như cách luật pháp bảo vệ bạn.
Chúng tôi sử dụng dữ liệu cá nhân của bạn để cung cấp và cải thiện Dịch vụ. Bằng cách sử dụng Dịch vụ, bạn đồng ý với việc thu thập và sử dụng thông tin theo Chính sách quyền riêng tư này. Chính sách quyền riêng tư này được tạo với sự trợ giúp của Trình tạo chính sách quyền riêng tư miễn phí.

"Rapid Loan" tập trung vào dịch vụ cho vay tiền mặt cho người dùng địa phương tại Việt Nam và giải quyết vấn đề quay vòng vốn của người dùng

Khách hàng có thể lựa chọn thời hạn vay, tối đa 365 ngày để trả nợ.
Hạn mức vay: lên tới 25,000,000 VNĐ.
Thời gian: 93 đến 365 ngày.
lãi suất ngày：0.03%/ngày
Lãi suất (APR): Lãi suất tối đa hàng năm là 10.95%/năm;
Phí dịch vụ: Không có.
Thuế GTGT: Không có

Ví dụ: bạn chọn sản phẩm vay 100 ngày và bạn vay 3,400,000đ qua sản phẩm này. Sau 100 ngày trả nợ, bạn phải thanh toán:
Lãi suất: 3,400,000*(10.95%/365) *100=102,000 VNĐ
Tổng số tiền: 3,400,000+102,000=3,502,000 VNĐ

Giải thích và định nghĩa
giải thích
Các từ có chữ cái đầu tiên viết hoa có nghĩa được xác định theo các điều kiện sau. Các định nghĩa sau đây có cùng ý nghĩa dù chúng xuất hiện ở số ít hay số nhiều.
sự định nghĩa
Vì mục đích của chính sách quyền riêng tư này:
Tài khoản nghĩa là một tài khoản duy nhất được tạo để bạn truy cập Dịch vụ của chúng tôi hoặc các phần Dịch vụ của chúng tôi.
Công ty liên kết là một thực thể kiểm soát, bị kiểm soát hoặc chịu sự kiểm soát chung của một bên, trong đó "kiểm soát" có nghĩa là quyền sở hữu từ 50% cổ phần trở lên, lợi ích vốn cổ phần hoặc các chứng khoán khác có quyền bỏ phiếu bầu giám đốc hoặc các tổ chức khác. cơ quan quản lý.
Chương trình ứng dụng đề cập đến chương trình phần mềm Rapid Loan do công ty chúng tôi cung cấp.
Công ty (trong Thỏa thuận này được gọi là "Công ty", "Chúng tôi", "Chúng tôi" hoặc "Của chúng tôi") đề cập đến BEN GREEN VIET NAM COMPANY LIMITED.
Quốc gia đề cập đến: Việt Nam
Thiết bị có nghĩa là bất kỳ thiết bị nào có thể truy cập Dịch vụ, chẳng hạn như máy tính, điện thoại di động hoặc máy tính bảng kỹ thuật số.
Dữ liệu cá nhân là bất kỳ thông tin nào liên quan đến một cá nhân được xác định hoặc có thể nhận dạng.
Dịch vụ đề cập đến các ứng dụng.
Nhà cung cấp dịch vụ có nghĩa là bất kỳ cá nhân hoặc pháp nhân nào xử lý dữ liệu thay mặt cho Công ty. Đề cập đến một công ty hoặc cá nhân bên thứ ba được Công ty tuyển dụng để quảng bá Dịch vụ, cung cấp Dịch vụ thay mặt Công ty, thực hiện các dịch vụ liên quan đến Dịch vụ hoặc hỗ trợ Công ty phân tích cách sử dụng Dịch vụ.

Dữ liệu sử dụng đề cập đến dữ liệu được thu thập tự động, được tạo thông qua việc sử dụng Dịch vụ hoặc từ chính cơ sở hạ tầng Dịch vụ (ví dụ: thời lượng truy cập trang).
Bạn có nghĩa là cá nhân truy cập hoặc sử dụng Dịch vụ, hoặc, nếu có, công ty hoặc pháp nhân khác thay mặt cho cá nhân đó đang truy cập hoặc sử dụng Dịch vụ.
Thu thập và sử dụng dữ liệu cá nhân của bạn
Các loại dữ liệu được thu thập
thông tin cá nhân
Trong khi sử dụng Dịch vụ của chúng tôi, chúng tôi có thể yêu cầu bạn cung cấp cho chúng tôi thông tin nhận dạng cá nhân nhất định có thể được sử dụng để liên hệ hoặc nhận dạng bạn. Thông tin nhận dạng cá nhân có thể bao gồm nhưng không giới hạn ở:
dữ liệu sử dụng
Dữ liệu sử dụng được thu thập tự động khi sử dụng Dịch vụ.
Dữ liệu sử dụng có thể bao gồm địa chỉ Giao thức Internet của thiết bị của bạn (ví dụ: địa chỉ IP), loại trình duyệt, phiên bản trình duyệt, các trang Dịch vụ của chúng tôi mà bạn truy cập, ngày và giờ bạn truy cập, thời gian dành cho các trang đó, các thiết bị riêng biệt, v.v. Thông tin nhận dạng và dữ liệu chẩn đoán khác.
Khi bạn truy cập Dịch vụ thông qua thiết bị di động, chúng tôi có thể tự động thu thập một số thông tin nhất định, bao gồm nhưng không giới hạn ở loại thiết bị di động bạn sử dụng, ID duy nhất của thiết bị di động, địa chỉ IP của thiết bị di động, hệ điều hành điện thoại di động của bạn, Loại của trình duyệt internet di động bạn sử dụng, số nhận dạng thiết bị duy nhất và dữ liệu chẩn đoán khác.
Chúng tôi cũng có thể thu thập thông tin mà trình duyệt của bạn gửi khi bạn truy cập Dịch vụ của chúng tôi hoặc truy cập Dịch vụ từ thiết bị di động.

Máy ảnh
Để cung cấp các dịch vụ bạn yêu cầu, ứng dụng này cần có quyền truy cập vào máy ảnh của bạn. Chúng tôi sẽ sử dụng quyền của máy ảnh để:
Cho phép bạn chụp ảnh hoặc quay video để hoàn tất xác minh danh tính và tải lên dữ liệu.
Giữ tài khoản của bạn an toàn.

Vị trí
Ứng dụng này cần lấy thông tin vị trí gần đúng của bạn để:
Xác minh khu vực địa lý của bạn để ngăn chặn gian lận.
Cung cấp nhiều dịch vụ được cá nhân hóa hơn và các đề xuất được bản địa hóa.

Thông tin thiết bị
Chúng tôi có thể thu thập và sử dụng thông tin về thiết bị của bạn, bao gồm nhưng không giới hạn ở kiểu thiết bị, phiên bản hệ điều hành, mã nhận dạng thiết bị duy nhất và thông tin mạng. Chúng tôi thu thập thông tin này để tối ưu hóa hiệu suất ứng dụng, đảm bảo tính tương thích, cung cấp trải nghiệm người dùng tùy chỉnh và ngăn chặn gian lận.

Tin nhắn ngắn
Để đảm bảo tính bảo mật cho tài khoản của bạn và xử lý suôn sẻ đơn xin vay tiền của bạn, chúng tôi có thể cần truy cập thông tin tin nhắn văn bản của bạn. Chúng tôi chỉ thu thập nội dung tin nhắn liên quan đến tình hình tài chính và các khoản vay của bạn để đánh giá tín dụng và phòng chống gian lận. Xin lưu ý rằng chúng tôi không gửi tin nhắn văn bản và chúng tôi không truy cập hoặc lưu trữ nội dung tin nhắn văn bản không liên quan đến khoản vay. Chúng tôi hứa rằng tất cả thông tin được thu thập sẽ chỉ được sử dụng cho mục đích đánh giá và sẽ bị xóa an toàn sau khi hoàn thành các nhiệm vụ liên quan.

lịch
Trong một số trường hợp, ứng dụng có thể cần truy cập vào thông tin lịch của bạn để lên lịch nhắc nhở thanh toán hoặc thông báo sự kiện quan trọng khác. Sự cho phép này sẽ giúp bạn quản lý tốt hơn ngày trả nợ của mình. Chúng tôi tôn trọng quyền riêng tư của bạn và sẽ không truy cập hoặc thay đổi bất kỳ thông tin nào khác trong lịch của bạn.

hồ sơ cuộc gọi
Để đảm bảo quá trình xác minh giọng nói diễn ra suôn sẻ, ứng dụng này cần có quyền truy cập vào lịch sử cuộc gọi của bạn. Điều này bao gồm thời lượng cuộc gọi, thông tin nhà cung cấp dịch vụ và trạng thái cuộc gọi. Bằng cách truy cập thông tin này, chúng tôi có thể xác nhận xem bạn có nhận được cuộc gọi mã xác minh của chúng tôi hay không. Tất cả dữ liệu thu thập được lưu trữ trên các máy chủ có độ bảo mật cao và chúng tôi không chia sẻ dữ liệu đó với bất kỳ bên thứ ba nào.

Sử dụng dữ liệu cá nhân của bạn
Công ty có thể sử dụng dữ liệu cá nhân cho các mục đích sau:
Để cung cấp và duy trì Dịch vụ của chúng tôi, bao gồm cả việc giám sát việc sử dụng Dịch vụ của chúng tôi.
Quản lý tài khoản của bạn: Quản lý việc đăng ký của bạn với tư cách là người dùng Dịch vụ. Dữ liệu cá nhân bạn cung cấp cho phép bạn truy cập các tính năng khác nhau của Dịch vụ có sẵn cho bạn với tư cách là người dùng đã đăng ký.
Để thực hiện hợp đồng: việc hình thành, tuân thủ và cam kết hợp đồng mua hàng đối với các sản phẩm, mặt hàng hoặc dịch vụ mà bạn mua hoặc bất kỳ hợp đồng nào khác được ký kết với chúng tôi thông qua Dịch vụ.
Để liên hệ với bạn: Để liên hệ với bạn qua email, điện thoại, SMS hoặc các hình thức liên lạc điện tử tương đương khác, chẳng hạn như thông báo đẩy trên ứng dụng di động về các cập nhật hoặc liên lạc thông tin liên quan đến tính năng, sản phẩm hoặc dịch vụ theo hợp đồng (bao gồm cả cập nhật bảo mật), được thực hiện khi cần thiết hoặc hợp lý.
Để cung cấp cho bạn tin tức, ưu đãi đặc biệt và thông tin chung về hàng hóa, dịch vụ và sự kiện khác mà chúng tôi cung cấp tương tự với những sản phẩm bạn đã mua hoặc hỏi, trừ khi bạn chọn không nhận những thông tin đó.
Quản lý yêu cầu của bạn: Tham dự và quản lý các yêu cầu bạn đưa ra cho chúng tôi.
Đối với chuyển nhượng kinh doanh: Chúng tôi có thể sử dụng thông tin của bạn để đánh giá hoặc thực hiện việc sáp nhập, thoái vốn, tái cơ cấu, tổ chức lại, giải thể hoặc bán hoặc chuyển nhượng một số hoặc tất cả tài sản của chúng tôi, cho dù là hoạt động liên tục hay do phá sản, thanh lý, hoặc các thủ tục tương tự trong đó dữ liệu cá nhân mà chúng tôi lưu giữ về người dùng dịch vụ của chúng tôi nằm trong số tài sản được chuyển giao.
Cho các mục đích khác: Chúng tôi có thể sử dụng thông tin của bạn cho các mục đích khác, chẳng hạn như phân tích dữ liệu, xác định xu hướng sử dụng, xác định tính hiệu quả của các chiến dịch quảng cáo của chúng tôi cũng như để đánh giá và cải thiện dịch vụ, sản phẩm, dịch vụ, hoạt động tiếp thị và trải nghiệm của bạn.
Chúng tôi có thể chia sẻ thông tin cá nhân của bạn trong các trường hợp sau:
Với các nhà cung cấp dịch vụ: Chúng tôi có thể chia sẻ thông tin cá nhân của bạn với các nhà cung cấp dịch vụ để theo dõi và phân tích việc sử dụng dịch vụ của chúng tôi và liên hệ với bạn.
Đối với chuyển nhượng kinh doanh: Chúng tôi có thể chia sẻ hoặc chuyển giao thông tin cá nhân của bạn liên quan đến hoặc trong quá trình đàm phán về việc sáp nhập với một công ty khác, bán tài sản công ty, tài trợ hoặc mua lại toàn bộ hoặc một phần hoạt động kinh doanh của chúng tôi.
Với các công ty liên kết: Chúng tôi có thể chia sẻ thông tin của bạn với các công ty liên kết của chúng tôi, trong trường hợp đó chúng tôi sẽ yêu cầu các công ty liên kết này tuân thủ Chính sách quyền riêng tư này. Các chi nhánh bao gồm công ty mẹ của chúng tôi và bất kỳ công ty con, đối tác liên doanh hoặc công ty nào khác mà chúng tôi kiểm soát hoặc chịu sự kiểm soát chung với chúng tôi.
Với Đối tác kinh doanh: Chúng tôi có thể chia sẻ thông tin của bạn với các đối tác kinh doanh của chúng tôi để cung cấp cho bạn một số sản phẩm, dịch vụ hoặc chương trình khuyến mãi nhất định.
Với những người dùng khác: Khi bạn chia sẻ thông tin cá nhân với những người dùng khác hoặc tương tác ở các khu vực công cộng, tất cả người dùng có thể xem thông tin đó và có thể được phân phối công khai ra bên ngoài.
Với sự đồng ý của bạn: Chúng tôi có thể tiết lộ thông tin cá nhân của bạn cho bất kỳ mục đích nào khác với sự đồng ý của bạn.
Lưu giữ dữ liệu cá nhân của bạn
Công ty sẽ chỉ lưu giữ dữ liệu cá nhân của bạn trong khoảng thời gian cần thiết cho các mục đích được nêu trong Chính sách quyền riêng tư này. Chúng tôi sẽ lưu giữ và sử dụng dữ liệu cá nhân của bạn trong phạm vi cần thiết để tuân thủ các nghĩa vụ pháp lý của chúng tôi (ví dụ: nếu chúng tôi được yêu cầu lưu giữ dữ liệu của bạn để tuân thủ luật hiện hành), giải quyết tranh chấp và thực thi các thỏa thuận và chính sách pháp lý của chúng tôi.
Công ty cũng sẽ lưu giữ Dữ liệu sử dụng cho mục đích phân tích nội bộ. Dữ liệu sử dụng thường được lưu giữ trong khoảng thời gian ngắn hơn trừ khi dữ liệu được sử dụng để tăng cường bảo mật hoặc cải thiện chức năng của Dịch vụ của chúng tôi hoặc chúng tôi có nghĩa vụ pháp lý phải lưu giữ dữ liệu trong thời gian dài hơn.

Chuyển dữ liệu cá nhân của bạn
Thông tin của bạn, bao gồm cả dữ liệu cá nhân, sẽ được xử lý tại văn phòng điều hành của Công ty và tại bất kỳ địa điểm nào khác có trụ sở của các bên liên quan đến việc xử lý. Điều này có nghĩa là thông tin này có thể được chuyển đến và lưu trữ trên các máy tính nằm ngoài tiểu bang, tỉnh, quốc gia của bạn hoặc khu vực pháp lý chính phủ khác nơi luật bảo vệ dữ liệu có thể khác với luật bảo vệ dữ liệu ở khu vực pháp lý của bạn.
Sự đồng ý của bạn với Chính sách quyền riêng tư này và việc gửi thông tin đó sau đó sẽ cấu thành sự đồng ý của bạn đối với việc chuyển giao đó.
Công ty sẽ thực hiện tất cả các bước cần thiết một cách hợp lý để đảm bảo rằng dữ liệu của bạn được xử lý an toàn và tuân thủ Chính sách quyền riêng tư này và sẽ không chuyển dữ liệu cá nhân của bạn cho bất kỳ tổ chức hoặc quốc gia nào trừ khi có các biện pháp kiểm soát thích hợp, bao gồm cả bảo mật dữ liệu của bạn. và các thông tin cá nhân khác.

Xóa dữ liệu cá nhân của bạn
Bạn có quyền xóa hoặc yêu cầu chúng tôi hỗ trợ xóa dữ liệu cá nhân mà chúng tôi đã thu thập về bạn.
Dịch vụ của chúng tôi có thể cung cấp cho bạn khả năng xóa một số thông tin nhất định về bạn khỏi Dịch vụ.
Bạn có thể cập nhật, sửa đổi hoặc xóa thông tin của mình bất kỳ lúc nào bằng cách đăng nhập vào tài khoản của bạn (nếu có) và truy cập phần Cài đặt tài khoản cho phép bạn quản lý thông tin cá nhân của mình. Bạn cũng có thể liên hệ với chúng tôi để yêu cầu quyền truy cập, chỉnh sửa hoặc xóa bất kỳ thông tin cá nhân nào bạn đã cung cấp cho chúng tôi.
Tuy nhiên, xin lưu ý rằng chúng tôi có thể cần lưu giữ một số thông tin nhất định khi chúng tôi có nghĩa vụ pháp lý hoặc cơ sở hợp pháp để làm như vậy.

Tiết lộ dữ liệu cá nhân của bạn
giao dịch kinh doanh
Nếu Công ty tham gia vào việc sáp nhập, mua lại hoặc bán tài sản, Dữ liệu cá nhân của bạn có thể được chuyển giao. Chúng tôi sẽ cung cấp thông báo trước khi dữ liệu cá nhân của bạn được chuyển giao và tuân theo chính sách bảo mật khác.
thực thi pháp luật
Trong một số trường hợp nhất định, Công ty có thể được yêu cầu tiết lộ dữ liệu cá nhân của bạn nếu pháp luật yêu cầu làm như vậy hoặc để đáp ứng yêu cầu hợp lệ của cơ quan công quyền (chẳng hạn như tòa án hoặc cơ quan chính phủ).
Các yêu cầu pháp lý khác
Công ty có thể tiết lộ Dữ liệu cá nhân của bạn với niềm tin rằng hành động đó là cần thiết để:
Tuân thủ các nghĩa vụ pháp lý
Bảo vệ và bảo vệ các quyền hoặc tài sản của Công ty
Ngăn chặn hoặc điều tra hành vi sai trái có thể xảy ra liên quan đến Dịch vụ
Bảo vệ sự an toàn cá nhân của người sử dụng dịch vụ hoặc công chúng
Tránh trách nhiệm pháp lý
Bảo mật dữ liệu cá nhân của bạn
Việc bảo mật Dữ liệu Cá nhân của bạn rất quan trọng đối với chúng tôi, nhưng hãy nhớ rằng không có phương thức truyền qua Internet hoặc phương pháp lưu trữ điện tử nào an toàn 100%. Mặc dù chúng tôi cố gắng sử dụng các phương tiện được chấp nhận về mặt thương mại để bảo vệ dữ liệu cá nhân của bạn nhưng chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của dữ liệu đó.

Quyền riêng tư của trẻ em
Dịch vụ của chúng tôi không hướng tới bất kỳ ai dưới 13 tuổi. Chúng tôi không cố ý thu thập thông tin nhận dạng cá nhân từ bất kỳ ai dưới 13 tuổi. Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng con bạn đã cung cấp Dữ liệu Cá nhân cho chúng tôi, vui lòng liên hệ với chúng tôi. Nếu chúng tôi phát hiện ra rằng chúng tôi đã thu thập dữ liệu cá nhân từ bất kỳ ai dưới 13 tuổi mà không có sự đồng ý của phụ huynh, chúng tôi sẽ thực hiện các bước để xóa thông tin đó khỏi máy chủ của mình.
Nếu chúng tôi cần dựa vào sự đồng ý làm cơ sở pháp lý để xử lý thông tin của bạn và bạn đang ở một quốc gia yêu cầu sự đồng ý của phụ huynh, chúng tôi có thể yêu cầu sự đồng ý của phụ huynh trước khi thu thập và sử dụng thông tin đó.

Liên kết đến các trang web khác
Dịch vụ của chúng tôi có thể chứa các liên kết đến các trang web khác không do chúng tôi điều hành. Nếu bạn nhấp vào liên kết của bên thứ ba, bạn sẽ được dẫn đến trang web của bên thứ ba đó. Chúng tôi đặc biệt khuyên bạn nên xem lại chính sách quyền riêng tư của mọi trang web bạn truy cập.
Chúng tôi không có quyền kiểm soát và không chịu trách nhiệm về nội dung, chính sách quyền riêng tư hoặc thông lệ của bất kỳ trang web hoặc dịch vụ nào của bên thứ ba.

Những thay đổi đối với Chính sách quyền riêng tư này
Thỉnh thoảng chúng tôi có thể cập nhật chính sách bảo mật của mình. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách quyền riêng tư mới trên trang này.
Chúng tôi sẽ thông báo cho bạn qua email và/hoặc thông báo nổi bật trên Dịch vụ của chúng tôi trước khi các thay đổi có hiệu lực và cập nhật ngày "Cập nhật lần cuối" ở đầu Chính sách quyền riêng tư này.
Bạn nên xem lại Chính sách quyền riêng tư này định kỳ để biết bất kỳ thay đổi nào. Các thay đổi đối với Chính sách quyền riêng tư này có hiệu lực khi chúng được đăng trên trang này.

liên hệ chúng tôi
Nếu bạn có bất kỳ câu hỏi nào về chính sách quyền riêng tư này, bạn có thể liên hệ với chúng tôi:
Qua email: office@hungphattmcompanylimited.com
Địa chỉ công ty: Số 113-115 Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, Thành phố Hồ Chí Minh
Website công ty: www.bengreenvietnamcompanylimited.com
Đường dây nóng dịch vụ khách hàng: +84 573891931
`,
      thText: `นโยบายความเป็นส่วนตัวนี้อธิบายนโยบายและขั้นตอนของเราในการรวบรวม การใช้ และการเปิดเผยข้อมูลของคุณเมื่อคุณใช้บริการ และแจ้งให้คุณทราบเกี่ยวกับสิทธิ์ความเป็นส่วนตัวของคุณและวิธีที่กฎหมายคุ้มครองคุณ
เราใช้ข้อมูลส่วนบุคคลของคุณเพื่อให้บริการและปรับปรุงบริการ โดยการใช้บริการ คุณยินยอมให้มีการรวบรวมและใช้ข้อมูลตามนโยบายความเป็นส่วนตัวนี้ นโยบายความเป็นส่วนตัวนี้สร้างขึ้นด้วยความช่วยเหลือของเครื่องมือสร้างนโยบายความเป็นส่วนตัวฟรี

“Rapid Loan” มุ่งเน้นไปที่การให้บริการสินเชื่อเงินสดแก่ผู้ใช้ในท้องถิ่นในประเทศไทย เพื่อแก้ไขปัญหาการหมุนเวียนเงินทุนของผู้ใช้

ลูกค้าสามารถเลือกระยะเวลาการกู้ยืม โดยมีระยะเวลาชำระคืนสูงสุด 365 วัน
วงเงินกู้: สูงสุด 40,000 บาท
ระยะเวลา: 93 ถึง 365 วัน
อัตราดอกเบี้ยรายวัน: 0.03%/วัน
อัตราดอกเบี้ย (APR): อัตราดอกเบี้ยสูงสุดต่อปีคือ 10.95%/ปี;
ค่าบริการ: ไม่มี.
ภาษีมูลค่าเพิ่ม: ไม่มี

ตัวอย่างเช่น คุณเลือกผลิตภัณฑ์สินเชื่อ 100 วัน และยืมเงิน 40,000 บาทผ่านผลิตภัณฑ์นี้ หลังจากชำระคืน 100 วัน คุณต้องชำระเงิน:
อัตราดอกเบี้ย: 40,000*(10.95%/365) *100 = 1,200 บาท
รวม: 40,000+1200 = 41,200 บาท

คำอธิบายและคำจำกัดความ
อธิบาย
ความหมายของคำที่ขึ้นต้นด้วยอักษรตัวแรกจะพิจารณาจากเงื่อนไขต่อไปนี้ คำจำกัดความต่อไปนี้มีความหมายเหมือนกันไม่ว่าจะปรากฏในรูปเอกพจน์หรือพหูพจน์ก็ตาม
คำนิยาม
เพื่อวัตถุประสงค์ของนโยบายความเป็นส่วนตัวนี้:
บัญชี หมายถึงบัญชีเฉพาะที่สร้างขึ้นเพื่อให้คุณเข้าถึงบริการของเราหรือบางส่วนของบริการของเรา
บริษัทในเครือคือหน่วยงานที่ควบคุม ถูกควบคุมโดย หรืออยู่ภายใต้การควบคุมเดียวกันกับฝ่ายใดฝ่ายหนึ่ง โดยที่ "การควบคุม" หมายถึงการเป็นเจ้าของหุ้น ทุน หรือหลักทรัพย์อื่น ๆ ตั้งแต่ 50% ขึ้นไป และมีสิทธิออกเสียงลงคะแนนให้กรรมการหรือหน่วยงานอื่น ๆ หน่วยงานปกครอง.
แอปพลิเคชันนี้อ้างอิงถึงโปรแกรมซอฟต์แวร์สินเชื่อด่วนที่บริษัทของเราจัดหาให้
บริษัท (ในข้อตกลงนี้เรียกว่า "บริษัท" "เรา" "พวกเรา" หรือ "ของเรา") หมายถึง BEN GREEN VIET NAM COMPANY LIMITED
ประเทศที่กล่าวถึง: ประเทศไทย
อุปกรณ์ หมายถึง อุปกรณ์ใดๆ ที่สามารถเข้าถึงบริการได้ เช่น คอมพิวเตอร์ โทรศัพท์มือถือ หรือแท็บเล็ตดิจิทัล
ข้อมูลส่วนบุคคลคือข้อมูลใด ๆ ที่เกี่ยวข้องกับบุคคลที่ระบุตัวตนหรือระบุตัวตนได้
บริการอ้างถึงแอปพลิเคชัน
ผู้ให้บริการ หมายถึง บุคคลหรือนิติบุคคลที่ประมวลผลข้อมูลในนามของบริษัท หมายถึงบริษัทบุคคลที่สามหรือบุคคลที่ได้รับการว่าจ้างจากบริษัทเพื่อส่งเสริมบริการ ให้บริการในนามของบริษัท ดำเนินการบริการที่เกี่ยวข้องกับบริการ หรือช่วยเหลือบริษัทในการวิเคราะห์วิธีการใช้บริการ

ข้อมูลการใช้งานหมายถึงข้อมูลที่รวบรวมโดยอัตโนมัติผ่านการใช้บริการหรือสร้างขึ้นจากโครงสร้างพื้นฐานของบริการเอง (เช่น ระยะเวลาของการเยี่ยมชมเพจ)
คุณหมายถึงบุคคลที่เข้าถึงหรือใช้บริการ หรือตามความเหมาะสม บริษัทหรือนิติบุคคลอื่น ๆ ในนามของบุคคลดังกล่าวกำลังเข้าถึงหรือใช้บริการ
การรวบรวมและการใช้ข้อมูลส่วนบุคคลของคุณ
ประเภทของข้อมูลที่เก็บรวบรวม
ข้อมูลส่วนบุคคล
ในขณะที่ใช้บริการของเรา เราอาจขอให้คุณมอบข้อมูลที่สามารถระบุตัวตนส่วนบุคคลบางอย่างให้เราได้ ซึ่งสามารถใช้เพื่อติดต่อหรือระบุตัวคุณได้ ข้อมูลที่สามารถระบุตัวตนส่วนบุคคลอาจรวมถึงแต่ไม่จำกัดเพียง:
ข้อมูลการใช้งาน
ข้อมูลการใช้งานจะถูกรวบรวมโดยอัตโนมัติเมื่อใช้บริการ
ข้อมูลการใช้งานอาจรวมถึงที่อยู่อินเทอร์เน็ตโปรโตคอลของอุปกรณ์ของคุณ (เช่น ที่อยู่ IP) ประเภทเบราว์เซอร์ เวอร์ชันของเบราว์เซอร์ หน้าบริการของเราที่คุณเยี่ยมชม วันที่และเวลาที่คุณเยี่ยมชม เวลาที่ใช้ในหน้าเหล่านั้น อุปกรณ์ส่วนตัว ฯลฯ . ข้อมูลการระบุตัวตนและข้อมูลการวินิจฉัยอื่น ๆ
เมื่อคุณเข้าถึงบริการผ่านอุปกรณ์มือถือ เราอาจรวบรวมข้อมูลบางอย่างโดยอัตโนมัติ รวมถึงแต่ไม่จำกัดเฉพาะประเภทของอุปกรณ์มือถือที่คุณใช้ ID เฉพาะของอุปกรณ์ ที่อยู่ IP ของอุปกรณ์มือถือ ระบบปฏิบัติการมือถือของคุณ เวอร์ชันอินเทอร์เน็ตเบราว์เซอร์บนมือถือ ตัวระบุอุปกรณ์ที่ไม่ซ้ำกัน และข้อมูลการวินิจฉัยอื่นๆ
เรายังอาจรวบรวมข้อมูลที่เบราว์เซอร์ของคุณส่งเมื่อคุณเยี่ยมชมบริการของเราหรือเข้าถึงบริการจากอุปกรณ์มือถือ

กล้อง
เพื่อให้บริการที่คุณร้องขอ แอปพลิเคชันนี้จำเป็นต้องเข้าถึงกล้องของคุณ เราจะใช้การอนุญาตของกล้องเพื่อ:
คุณสามารถยืนยันตัวตนและอัปโหลดข้อมูลได้โดยการถ่ายภาพหรือบันทึกวิดีโอ
รักษาบัญชีของคุณให้ปลอดภัย

สถานที่
แอปพลิเคชันนี้จำเป็นต้องได้รับตำแหน่งโดยประมาณของคุณ:
ตรวจสอบพื้นที่ทางภูมิศาสตร์ของคุณเพื่อป้องกันการฉ้อโกง
ให้บริการที่เป็นส่วนตัวมากขึ้นและคำแนะนำที่แปลเป็นภาษาท้องถิ่น

ข้อมูลอุปกรณ์
เราอาจรวบรวมและใช้ข้อมูลเกี่ยวกับอุปกรณ์ของคุณ รวมถึงแต่ไม่จำกัดเพียงรุ่นอุปกรณ์ เวอร์ชันระบบปฏิบัติการ ตัวระบุอุปกรณ์ที่ไม่ซ้ำกัน และข้อมูลเครือข่าย เรารวบรวมข้อมูลนี้เพื่อเพิ่มประสิทธิภาพการทำงานของแอปพลิเคชัน รับประกันความเข้ากันได้ มอบประสบการณ์ผู้ใช้ที่ปรับแต่งเอง และป้องกันการฉ้อโกง

ข้อความสั้นๆ
เพื่อให้มั่นใจในความปลอดภัยของบัญชีของคุณและการประมวลผลการสมัครสินเชื่อของคุณราบรื่น เราอาจจำเป็นต้องเข้าถึงข้อมูลข้อความของคุณ เรารวบรวมเฉพาะเนื้อหาข้อความที่เกี่ยวข้องกับสถานการณ์ทางการเงินและการกู้ยืมของคุณเพื่อการประเมินเครดิตและการป้องกันการฉ้อโกง โปรดทราบว่าเราไม่ส่งข้อความ และเราไม่เข้าถึงหรือจัดเก็บเนื้อหาข้อความที่ไม่เกี่ยวข้องกับการกู้ยืม เราสัญญาว่าข้อมูลทั้งหมดที่เก็บรวบรวมจะถูกใช้เพื่อวัตถุประสงค์ในการประเมินเท่านั้น และจะถูกลบอย่างปลอดภัยหลังจากงานที่เกี่ยวข้องเสร็จสิ้น

ปฏิทิน
ในบางกรณี แอปพลิเคชันอาจจำเป็นต้องเข้าถึงข้อมูลปฏิทินของคุณเพื่อกำหนดเวลาการแจ้งเตือนการชำระเงินหรือการแจ้งเตือนเหตุการณ์สำคัญอื่น ๆ การอนุญาตนี้จะช่วยให้คุณจัดการวันชำระหนี้ได้ดีขึ้น เราเคารพความเป็นส่วนตัวของคุณและจะไม่เข้าถึงหรือเปลี่ยนแปลงข้อมูลอื่นใดในปฏิทินของคุณ

ประวัติการโทร
เพื่อให้การยืนยันด้วยเสียงดำเนินไปอย่างราบรื่น แอปนี้จำเป็นต้องเข้าถึงประวัติการโทรของคุณ ซึ่งรวมถึงระยะเวลาการโทร ข้อมูลผู้ให้บริการ และสถานะการโทร ด้วยการเข้าถึงข้อมูลนี้ เราสามารถยืนยันได้ว่าคุณได้รับการโทรด้วยรหัสยืนยันของเราหรือไม่ ข้อมูลทั้งหมดที่เก็บรวบรวมจะถูกจัดเก็บไว้ในเซิร์ฟเวอร์ที่มีความปลอดภัยสูงและเราจะไม่แชร์ข้อมูลดังกล่าวกับบุคคลที่สาม

การใช้ข้อมูลส่วนบุคคลของคุณ
บริษัทอาจใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ดังต่อไปนี้:
เพื่อจัดหาและบำรุงรักษาบริการของเรา รวมถึงการตรวจสอบการใช้บริการของเรา
จัดการบัญชีของคุณ: จัดการการลงทะเบียนของคุณในฐานะผู้ใช้บริการ ข้อมูลส่วนบุคคลที่คุณให้ไว้ช่วยให้คุณสามารถเข้าถึงคุณสมบัติต่างๆ ของบริการที่มีให้คุณในฐานะผู้ใช้ที่ลงทะเบียน
การปฏิบัติตามสัญญา: การจัดทำ การปฏิบัติตาม และความมุ่งมั่นของสัญญาการซื้อผลิตภัณฑ์ รายการ หรือบริการที่คุณซื้อหรือสัญญาอื่นใดที่ทำกับเราผ่านทางบริการ
เพื่อติดต่อคุณ: เพื่อติดต่อคุณทางอีเมล โทรศัพท์ ข้อความ หรือรูปแบบการสื่อสารทางอิเล็กทรอนิกส์อื่นที่เทียบเท่า เช่น การแจ้งเตือนแบบพุชของแอปพลิเคชันมือถือเกี่ยวกับการอัปเดตหรือการสื่อสารข้อมูลที่เกี่ยวข้องกับคุณสมบัติตามสัญญา ผลิตภัณฑ์ หรือบริการ (รวมถึงการอัปเดตความปลอดภัย) จำเป็นหรือสมเหตุสมผล .
เพื่อมอบข่าวสาร ข้อเสนอพิเศษ และข้อมูลทั่วไปเกี่ยวกับสินค้า บริการและกิจกรรมอื่น ๆ ที่เรานำเสนอซึ่งคล้ายกับสินค้าที่คุณซื้อหรือสอบถาม เว้นแต่คุณจะเลือกที่จะไม่รับข้อมูลดังกล่าว
จัดการคำขอของคุณ: ติดตามและจัดการคำขอของคุณที่ส่งถึงเรา
สำหรับการโอนธุรกิจ: เราอาจใช้ข้อมูลของคุณเพื่อประเมินหรือส่งผลต่อการควบรวมกิจการ การขายกิจการ การปรับโครงสร้างใหม่ การปรับโครงสร้างองค์กร การเลิกกิจการ หรือการขาย หรือการโอนสินทรัพย์บางส่วนหรือทั้งหมดของเรา ไม่ว่าจะเป็นการดำเนินงานต่อเนื่องหรือเป็นผลมาจากการล้มละลาย การชำระบัญชี หรือที่คล้ายกัน สถานการณ์ที่โปรแกรม ข้อมูลส่วนบุคคลที่เราเก็บไว้เกี่ยวกับผู้ใช้บริการของเราเป็นทรัพย์สินของเรา
เพื่อวัตถุประสงค์อื่น: เราอาจใช้ข้อมูลของคุณเพื่อวัตถุประสงค์อื่น เช่น การวิเคราะห์ข้อมูล การระบุแนวโน้มการใช้งาน การพิจารณาประสิทธิภาพของแคมเปญโฆษณาของเรา และการประเมินและปรับปรุงบริการ ผลิตภัณฑ์ บริการ การตลาด และประสบการณ์ของคุณ
เราอาจแบ่งปันข้อมูลส่วนบุคคลของคุณในสถานการณ์ต่อไปนี้:
กับผู้ให้บริการ: เราอาจแบ่งปันข้อมูลส่วนบุคคลของคุณกับผู้ให้บริการเพื่อติดตามและวิเคราะห์การใช้บริการของเราและเพื่อติดต่อคุณ
สำหรับการโอนธุรกิจ: เราอาจแบ่งปันหรือถ่ายโอนข้อมูลส่วนบุคคลของคุณที่เกี่ยวข้องหรือในระหว่างการเจรจาการควบรวมกิจการกับบริษัทอื่น การขายสินทรัพย์ของบริษัท การจัดหาเงินทุน หรือการซื้อกิจการทั้งหมดหรือบางส่วนของเรา
กับบริษัทในเครือ: เราอาจแบ่งปันข้อมูลของคุณกับบริษัทในเครือของเรา ซึ่งในกรณีนี้เราจะกำหนดให้บริษัทในเครือเหล่านี้ปฏิบัติตามนโยบายความเป็นส่วนตัวนี้ บริษัทในเครือได้แก่บริษัทแม่และบริษัทย่อย หุ้นส่วนร่วมทุน หรือบริษัทอื่นๆ ที่เราควบคุมหรืออยู่ภายใต้การควบคุมเดียวกันกับเรา
กับพันธมิตรทางธุรกิจ: เราอาจแบ่งปันข้อมูลของคุณกับพันธมิตรทางธุรกิจของเราเพื่อจัดหาผลิตภัณฑ์ บริการ หรือโปรโมชั่นบางอย่างให้กับคุณ
กับผู้ใช้รายอื่น: เมื่อคุณแบ่งปันข้อมูลส่วนบุคคลกับผู้ใช้รายอื่นหรือโต้ตอบในพื้นที่สาธารณะ ผู้ใช้ทุกคนสามารถดูข้อมูลนั้นได้และอาจเผยแพร่ต่อสาธารณะสู่ภายนอก
ด้วยความยินยอมของคุณ: เราอาจเปิดเผยข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ใด ๆ โดยไม่ได้รับความยินยอมจากคุณ
การเก็บรักษาข้อมูลส่วนบุคคลของคุณ
บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของคุณไว้นานเท่าที่จำเป็นเพื่อวัตถุประสงค์ที่กำหนดไว้ในนโยบายความเป็นส่วนตัวนี้ เราจะเก็บรักษาและใช้ข้อมูลส่วนบุคคลของคุณตามขอบเขตที่จำเป็นเพื่อปฏิบัติตามภาระผูกพันทางกฎหมายของเรา (เช่น หากเราจำเป็นต้องเก็บข้อมูลของคุณเพื่อให้เป็นไปตามกฎหมายที่บังคับใช้) แก้ไขข้อพิพาท และบังคับใช้ข้อตกลงทางกฎหมายและนโยบายของเรา
บริษัทจะเก็บข้อมูลการใช้งานเพื่อวัตถุประสงค์ในการวิเคราะห์ภายในด้วย โดยทั่วไปข้อมูลการใช้งานจะถูกเก็บรักษาไว้ในช่วงระยะเวลาที่สั้นกว่า เว้นแต่ว่าข้อมูลนั้นจะถูกนำมาใช้เพื่อเพิ่มความปลอดภัยหรือปรับปรุงการทำงานของบริการของเรา หรือเรามีภาระผูกพันทางกฎหมายที่จะต้องเก็บรักษาข้อมูลไว้เป็นระยะเวลานานขึ้น

ถ่ายโอนข้อมูลส่วนบุคคลของคุณ
ข้อมูลของคุณ รวมถึงข้อมูลส่วนบุคคล จะถูกประมวลผลที่สำนักงานปฏิบัติการของบริษัท และที่สถานที่อื่นใดที่ฝ่ายที่เกี่ยวข้องในการประมวลผลตั้งอยู่ ซึ่งหมายความว่าข้อมูลนี้อาจถูกถ่ายโอนไปยังและดูแลรักษาบนคอมพิวเตอร์ที่อยู่นอกรัฐ จังหวัด ประเทศ หรือเขตอำนาจศาลอื่น ๆ ของรัฐบาล ซึ่งกฎหมายคุ้มครองข้อมูลอาจแตกต่างไปจากกฎหมายคุ้มครองข้อมูลในเขตอำนาจศาลของคุณ
ข้อตกลงของคุณต่อนโยบายความเป็นส่วนตัวนี้และการส่งข้อมูลดังกล่าวในภายหลังถือเป็นการยินยอมของคุณต่อการโอนนั้น
บริษัทจะดำเนินการทุกขั้นตอนที่จำเป็นตามสมควรเพื่อให้แน่ใจว่าข้อมูลของคุณได้รับการปฏิบัติอย่างปลอดภัยและเป็นไปตามนโยบายความเป็นส่วนตัวนี้ และจะไม่ส่งข้อมูลส่วนบุคคลของคุณไปยังองค์กรหรือประเทศอื่นใด เว้นแต่จะมีการควบคุมที่เหมาะสม รวมถึงเพื่อความปลอดภัยของคุณ ข้อมูล. และข้อมูลส่วนบุคคลอื่น ๆ

ลบข้อมูลส่วนบุคคลของคุณ
คุณมีสิทธิ์ที่จะลบหรือขอความช่วยเหลือจากเราในการลบข้อมูลส่วนบุคคลที่เรารวบรวมเกี่ยวกับคุณ
บริการของเราอาจทำให้คุณสามารถลบข้อมูลบางอย่างเกี่ยวกับคุณออกจากบริการได้
คุณสามารถอัปเดต แก้ไข หรือลบข้อมูลของคุณได้ตลอดเวลาโดยลงชื่อเข้าใช้บัญชีของคุณ (หากคุณมีบัญชี) และเข้าถึงส่วนการตั้งค่าบัญชีที่อนุญาตให้คุณจัดการข้อมูลส่วนบุคคลของคุณ คุณยังอาจติดต่อเราเพื่อขอเข้าถึง แก้ไข หรือลบข้อมูลส่วนบุคคลใด ๆ ที่คุณให้ไว้กับเรา
อย่างไรก็ตาม โปรดทราบว่าเราอาจจำเป็นต้องเก็บข้อมูลบางอย่างไว้ หากเรามีหน้าที่ตามกฎหมายหรือมีพื้นฐานที่ถูกต้องตามกฎหมายในการดำเนินการดังกล่าว

การเปิดเผยข้อมูลส่วนบุคคลของคุณ
ธุรกรรมทางธุรกิจ
หากบริษัทเกี่ยวข้องกับการควบรวมกิจการ การซื้อกิจการ หรือการขายสินทรัพย์ ข้อมูลส่วนบุคคลของคุณอาจถูกถ่ายโอน เราจะแจ้งให้ทราบก่อนที่ข้อมูลส่วนบุคคลของคุณจะถูกโอนและอยู่ภายใต้นโยบายความเป็นส่วนตัวอื่น
การบังคับใช้กฎหมาย
ในบางสถานการณ์ บริษัทอาจจำเป็นต้องเปิดเผยข้อมูลส่วนบุคคลของคุณหากจำเป็นต้องทำตามกฎหมายหรือเพื่อตอบสนองต่อการร้องขอที่ถูกต้องจากหน่วยงานของรัฐ (เช่น ศาลหรือหน่วยงานของรัฐ)
ข้อกำหนดทางกฎหมายอื่น ๆ
บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของคุณด้วยความเชื่อโดยสุจริตว่าการกระทำดังกล่าวมีความจำเป็นเพื่อ:
ปฏิบัติตามภาระผูกพันทางกฎหมาย
ปกป้องและปกป้องสิทธิหรือทรัพย์สินของบริษัท
ป้องกันหรือสอบสวนการประพฤติมิชอบที่อาจเกิดขึ้นเกี่ยวกับบริการ
ปกป้องความปลอดภัยส่วนบุคคลของผู้ใช้บริการหรือสาธารณะ
หลีกเลี่ยงความรับผิดชอบ
ปกป้องข้อมูลส่วนบุคคลของคุณ
การรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณเป็นสิ่งสำคัญสำหรับเรา แต่โปรดจำไว้ว่าไม่มีวิธีการส่งข้อมูลทางอินเทอร์เน็ตหรือวิธีการจัดเก็บทางอิเล็กทรอนิกส์ใดที่ปลอดภัย 100% แม้ว่าเรามุ่งมั่นที่จะใช้วิธีการที่ยอมรับได้ในเชิงพาณิชย์เพื่อปกป้องข้อมูลส่วนบุคคลของคุณ แต่เราไม่สามารถรับประกันความปลอดภัยได้อย่างสมบูรณ์

ความเป็นส่วนตัวของเด็ก
บริการของเราไม่ได้มุ่งเป้าไปที่ผู้ใดก็ตามที่มีอายุต่ำกว่า 13 ปี เราไม่รวบรวมข้อมูลที่สามารถระบุตัวบุคคลได้จากผู้ที่มีอายุต่ำกว่า 13 ปีโดยเจตนา หากคุณเป็นพ่อแม่หรือผู้ปกครองและคุณทราบว่าบุตรหลานของคุณได้ให้ข้อมูลส่วนบุคคลแก่เรา โปรดติดต่อเรา หากเราพบว่าเราได้รวบรวมข้อมูลส่วนบุคคลจากใครก็ตามที่มีอายุต่ำกว่า 13 ปีโดยไม่ได้รับความยินยอมจากผู้ปกครอง เราจะดำเนินการเพื่อลบข้อมูลนั้นออกจากเซิร์ฟเวอร์ของเรา
หากเราจำเป็นต้องอาศัยความยินยอมเป็นพื้นฐานทางกฎหมายในการประมวลผลข้อมูลของคุณ และคุณอยู่ในประเทศที่ต้องได้รับความยินยอมจากผู้ปกครอง เราอาจขอความยินยอมจากผู้ปกครองก่อนเมื่อรวบรวมและใช้ข้อมูลนั้น

ลิงค์ไปยังเว็บไซต์อื่น
บริการของเราอาจมีลิงก์ไปยังเว็บไซต์อื่นที่ไม่ได้ดำเนินการโดยเรา หากคุณคลิกลิงก์ของบุคคลที่สาม คุณจะถูกนำไปยังเว็บไซต์ของบุคคลที่สามนั้น เราขอแนะนำอย่างยิ่งให้คุณตรวจสอบนโยบายความเป็นส่วนตัวของทุกเว็บไซต์ที่คุณเยี่ยมชม
เราไม่สามารถควบคุมและไม่รับผิดชอบต่อเนื้อหา นโยบายความเป็นส่วนตัว หรือแนวปฏิบัติของไซต์หรือบริการของบุคคลที่สาม

การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้
เราอาจปรับปรุงนโยบายความเป็นส่วนตัวของเราเป็นครั้งคราว เราจะแจ้งให้คุณทราบถึงการเปลี่ยนแปลงใด ๆ โดยการโพสต์นโยบายความเป็นส่วนตัวใหม่ในหน้านี้
เราจะแจ้งให้คุณทราบทางอีเมลและ/หรือประกาศที่ชัดเจนเกี่ยวกับบริการของเราก่อนที่การเปลี่ยนแปลงจะมีผล และอัปเดตวันที่ "อัปเดตล่าสุด" ที่ด้านบนของนโยบายความเป็นส่วนตัวนี้
คุณควรตรวจสอบนโยบายความเป็นส่วนตัวนี้เป็นระยะเพื่อดูการเปลี่ยนแปลงใดๆ การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้จะมีผลเมื่อมีการโพสต์ในหน้านี้

ติดต่อเรา
หากคุณมีคำถามใด ๆ เกี่ยวกับนโยบายความเป็นส่วนตัวนี้ คุณสามารถติดต่อเรา:
ทางอีเมล: office@hungphattmcompanylimited.com
ที่อยู่บริษัท: เลขที่ 113-115 Ung Van Khiem, Ward 25, Binh Thanh District, โฮจิมินห์ซิตี้
เว็บไซต์บริษัท: www.bengreenvietnamcompanylimited.com
สายด่วนบริการลูกค้า: +66 573891931`,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img5Box {
  margin-top: 50px;
}
.textBox{
  color:#333333;
  white-space: pre-wrap;
}
</style>
